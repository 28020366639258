.login-page{
    margin: 128px 0;
}

.login-page .grid-login-page .form-login{
    text-align: left;
}

.login-page .grid-login-page .form-login-email{
    
}
.login-page .grid-login-page .form-login-email label{
    
}
.login-page .grid-login-page .form-login-email input{
    background-color: #e8f0fe;
}

.login-page .grid-login-page .form-login-pass{
    margin-top: 12px;
}
.login-page .grid-login-page .form-login-pass label{
    
}
.login-page .grid-login-page .form-login-pass input{
    background-color: #e8f0fe;
}

.login-page .grid-login-page .button-submit-login{
    margin-top: 24px;
    width: 100%;
    padding: 8px 0;
    border-radius: 4px;
    border: none;
    background-color: #042893;
    color: #fff;
}

.login-page .grid-login-page .button-submit-login:hover{
    background-color: #063cdd;
    cursor: pointer;
}

.login-page .footer-login{
    text-align: left;
    position: fixed;
    width: 100%;
    margin-top: 28vh;
    font-size: 12px;
    height: 30px;
    padding: 12px;
    border-top: 1px solid #dee2ea;
    /* background-color: #042893;
    color: white; */
}