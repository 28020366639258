.modal-create-admin-content{
    margin-top: 80px;
    padding: 0 18px;
}

.modal-create-admin-content .modal-create-admin-title{
    /* display: flex; */
    padding: 0 18px;
    /* justify-content: left; */
    color: #001737;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-align: left;
    margin-right: auto;
    font-size: 20px;
}

.modal-create-admin-content .modal-create-admin-form-group{
    /* display: flex; */
    padding: 0 18px;
    /* justify-content: left; */
    color: #001737;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-right: auto;
    margin-bottom: 0;
    font-size: 14px;
}
.modal-create-admin-content .modal-create-admin-form-group .form-item-group{
    margin-bottom: 12px;
}
.modal-create-admin-content .modal-create-admin-form-group p{
    /* display: flex; */
    margin-bottom: 0;
}

.modal-create-admin-content .modal-create-admin-form-group input{
    /* display: flex; */
    width: 100%;
    height: 36px;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.modal-create-admin-content .modal-create-admin-form-group .form-submit input{
    width: 12%;
    height: 36px;
    background-color: #0168fa;
    color: #fff;
    margin-top: 24px;
    border: 1px solid #ced4da;
}