.price{

}

.price .price-content{
    margin-top: 128px;
}

.price .price-content .price-pricing{
    margin-bottom: 100px;
}
.price .price-content .price-pricing .price-pricing-toggle{
    margin-bottom: 48px;
}
.MuiCard-root{
    border-radius: 16px !important;
}
.MuiCardContent-root{
    padding: 0 !important;
}
.price .price-content .price-pricing .price-card-title{
    /* margin: 48px; */
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #0085cd;
    padding: 48px 0;
    margin: 0;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    /* font-size: 18px; */
}

.price .price-content .price-pricing .price-card-price{
    /* margin: 48px; */
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #f67300;
    padding: 12px 0;
}
.price-card-price-1{
    margin: 0;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 19px;
}
.price-card-price-2{
    margin: 0;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 12px;
}
.price .price-content .price-pricing .price-card-detail{
    /* margin: 48px; */
    background-color: #ffffff;
    text-align: left;
    padding: 24px;
}
.price .price-content .price-pricing .price-card-action{
    /* margin: 48px; */
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #4eb429;
    color: #fff;
}
.price .price-content .price-pricing .price-card-action Button{
    color: #fff;
}
.price .price-content .price-pricing .price-pricing-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 32px;
}
.MuiCard-root{
    background-color: #000;
    padding: 0 !important;
}

.price .price-content .price-features{
    margin-bottom: 128px;
}
.price .price-content .price-features .price-features-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 32px;
    margin-bottom: 32px;
}

.price .price-content .price-plans{
    margin-bottom: 64px;
}
.price .price-content .price-plans .price-plans-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 32px;
    margin-bottom: 32px;
}

.price .price-content .price-faq{
    margin-bottom: 128px;
}
.price .price-content .price-faq .price-faq-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 32px;
    margin-bottom: 32px;
}
.price .price-content .desc-price-faq{
    text-align: left;
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 16px;
}