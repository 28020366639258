.home-admin {

}

.home-admin .home-admin-content {
    margin-top: 64px;
}

.home-admin .home-admin-content {
    /* margin-bottom: 80px; */
}

.home-admin .home-admin-content .home-admin-content-title {
    color: #031d6b;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    margin: 2px 0 32px 0;
    font-size: 24px;
}

.home-admin .home-admin-content .home-admin-content-break{
    display: block;
    border-bottom: 1px solid rgba(3, 29, 107, 0.4);
    margin: 0 28px 48px 28px;
}

.home-admin .home-admin-content .home-admin-content-button-group{
    /* padding: 0 180px; */
}

.home-admin-content-button-group .link-button-home{
    
}
.home-admin-content-button-group .link-button-home a{
    cursor: pointer;
}
.home-admin-content-button-group .link-button-home button{
    cursor: pointer;
    width: 220px;
    height: 180px;
    background-color: #fff;
    box-shadow: 8px 8px 8px rgba(3, 29, 107, 0.08);
    border: 1px solid rgba(3, 29, 107, 0.1);
    border-radius: 18px;
    padding: 8px 32px;
    margin-bottom: 32px;
}
.home-admin-content-button-group .link-button-home .button-home-text{
    margin-top: 12px;
}
.home-admin-content-button-group .link-button-home .button-home-text p{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
}



