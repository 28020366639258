.navbar{
    background-color: #ffffff;
    padding: 0;
}
.title-top-navbar p{
    display: flex;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 12px;
    margin-right: 8px;
    margin-bottom: 0;
}
.f-navbar{
    background-color: #444444;
    color: #fff;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: right;
}
.button-top-navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #77da55;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    text-align: left;
    padding: 4px;
    margin: 0 !important;
    border-radius: 0;
}
.button-top-navbar:hover{
    background-color: #000;
    color: #fff;
}
.button-top-navbar .icon-top-navbar{
    font-size: 18px;
    margin-right: 2px;
}
.navbar .s-navbar{
    padding: 12px 32px;
    /* background-color: #e81500; */
}
.navbar-brand{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    /* margin-right: 32px; */
}
.nav-item{
    font-weight: 500;
    color: #000;
}
.nav-item .small-bar-price{
    background-color: #e81500;
    color: #fff;
    padding: 0 4px;
    border-radius: 4px;
    position: absolute;
    margin-left: 32px;
    font-size: 10px;
    /* right: 2px; */
    z-index: 4;
}
.nav-item .small-bar-cloud{
    background-color: #0061f2;
    color: #fff;
    padding: 0 4px;
    border-radius: 4px;
    position: absolute;
    margin-left: 80px;
    font-size: 10px;
    /* right: 2px; */
    z-index: 4;
}
.nav-link{
    color: #000 !important;
}

.button-login{
    color: #b98528;
    border: solid 1px #b98528;
    border-radius: 18px;
    padding: 6px 32px;
}
.button-login:hover{
    color: #fff;
    background-color: #b98528;
    border: solid 1px #fff;
    border-radius: 18px;
}

.button-wiki{
    color: #b98528;
    border: solid 1px #b98528;
    border-radius: 18px;
    padding: 6px 32px;
}
.button-wiki:hover{
    color: #fff;
    background-color: #b98528;
    border: solid 1px #fff;
    border-radius: 18px;
}

.button-customer-portal{
    color: #fff;
    background-color: #b98528;
    border: solid 1px #b98528;
    border-radius: 18px;
    padding: 6px 18px;
}
.button-customer-portal:hover{
    color: #fff;
    background-color: #c89f56;
    border: solid 1px #fff;
    border-radius: 18px;
}