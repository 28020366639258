.property-solution{
    /* margin-bottom: 128px; */
}

.title-property-solution{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 0 !important;
    margin-right: auto;
}

.grid-item{
    display: flex;
    justify-content: start;
    align-items: start;
}

.grid-children-container{
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: left;
    padding: 0px 0px 8px 8px;
}

.grid-children-item-title{
    color: #2B2B2B;
}

.grid-children-item-content{
    color: #363D47;
}