
.header{
    background-color: #f5f5f5;
}
.header .header-bg{
    background-color: #f5f5f5;
    height: 550px;
    background-position: center;
    background-size: cover;
    position: relative;
    /* border-radius: 0 0 180px 180px; */
    border-radius:640px / 50px;
}

.content{

}

.content .propertysolution-container{
    background-color: #f5f5f5;
    padding-top: 80px;
    /* padding-bottom: 80px; */
}
.content .businesssolution-container{
    background-color: #f7fbfe;
}
.content .curved-1{
    background-color: #f5f5f5;
    border-radius: 0% 0% 100% 100%;
    height: 100px;
    margin-bottom: 64px;
}
.content .curved-2{
    background-color: #f7fbfe;
    border-radius: 0% 0% 100% 100%;
    height: 100px;
    margin-bottom: 64px;
}
.content .ourclient-container{
    background-color: #f5f5f5;
    padding-bottom: 80px;
}
.content .strategicpartner-container{
    background-color: #f5f5f5;
    padding-top: 64px;
}
.content .questionsection-container{
    background-color: #eff3f9;
    /* padding-bottom: 64px; */
}

.content .sp-curved-1{
    background-color: #ededed;
    height: 100px;
}
.content .sp-curved-2{
    background-color: #eff3f9;
    border-radius: 0% 0% 100% 100%;
    height: 100px;
}

