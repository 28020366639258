h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  /*color: #323f52;*/
}

h1, .h1 {
font-size: 1.5rem;
padding-bottom: 0.5rem;
}

h2, .h2 {
font-size: 1.4rem;
padding-bottom: 0.5rem;
}

h3, .h3 {
font-size: 1.3rem;
padding-bottom: 0.5rem;
}

h4, .h4 {
font-size: 1.2rem;
padding-bottom: 0.5rem;
}

h5, .h5 {
font-size: 1.1rem;
padding-bottom: 0.5rem;
}

h6, .h6 {
font-size: 1rem;
padding-bottom: 0.5rem;
}

.display-1 {
font-size: 5.5rem;
font-weight: 300;
line-height: 1.2;
}

.display-2 {
font-size: 4.5rem;
font-weight: 300;
line-height: 1.2;
}

.display-3 {
font-size: 3.5rem;
font-weight: 300;
line-height: 1.2;
}

.display-4 {
font-size: 2.5rem;
font-weight: 300;
line-height: 1.2;
}

hr {
margin-top: 1rem;
margin-bottom: 1rem;
border: 0;
border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.underline{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-bottom: 1px solid #E0E0E0;
}