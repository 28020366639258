.solution{
    margin-bottom: 128px;
}

.solution-content-title{
    color: #363d47;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 48px;
    margin-top: 48px;
    font-size: 28px;
}
.header-bg-title{
    color: #363D47;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 28px;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    padding: 24px 24px 0 24px;
}
.header-bg-desc{
    color: #363D47;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    padding: 0 24px 24px 24px;
}

.solution .container .react-quill-content{
    background-color: #fff;
    box-shadow: 2px 2px 12px rgba(3, 29, 107, 0.08);
    border: 1px solid rgba(3, 29, 107, 0.08);
    border-radius: 12px;
    padding: 8px;
}

.solution .container .react-quill-content p{
    color: #687281;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    margin-right: auto;
    padding: 12px 12px 12px 0px;
}

.solution .container .react-quill-content ul{
    /* position: relative; */
	/* list-style: none;
	margin-left: 0;
	padding-left: 1.2em; */
}
.solution .container .react-quill-content ul li::before{
    content: "✔";
}
.solution .container .react-quill-content li{
    content: "+";
	/* position: absolute; */
	left: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    margin-bottom: 8px;
}