.private-cloud{
    padding-top: 128px;
}

.private-cloud-content .private-cloud-card{
    margin-bottom: 64px;
    justify-content: center;
    align-items: center;
}
.private-cloud-card-detail{
    width: 400px;
    padding: 24px;
}
.private-cloud-content .private-cloud-card-content{
    margin-bottom: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.private-cloud-content .private-cloud-card .private-cloud-card-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 32px;
    margin-right: auto;
    font-size: 32px;
}

.private-cloud-card-content-title{
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 24px;
}
.private-cloud-card-content-desc{
    color: #687281;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 16px;
}
.private-cloud-card-content-content{
    
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
}
.card-price-1{
    padding: 24px 0;
    font-size: 16px;
}
.card-price-2{
    font-size: 80px;
}
.card-price-3{
    padding: 24px 0;
    font-size: 16px;
}
.card-price-4{
    padding: 24px 0;
    font-size: 16px;
    display: flex;
    align-items: flex-end;
}

.private-cloud-content .private-cloud-faq{
    margin-bottom: 128px;
}
.private-cloud-content .private-cloud-faq .private-cloud-faq-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 32px;
    margin-right: auto;
    font-size: 32px;
}