.header-bg-partner{
    
    height: 350px;
    background-position: center;
    background-size: cover;
    position: relative;
    /* border-radius: 0 0 180px 180px; */
    margin-bottom: 100px;
    /* border-radius:640px / 50px; */
}
.header-title{
    padding-top: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 65px;
}
.partnership-content-desc{
    margin-bottom: 128px;
}

.partnership-content-desc-text{
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 18px;
}
.partnership-content-contact{
    padding-top: 80px;
    background-color: #eff3f9;
}
.sp-curved-1{
    background-color: #ededed;
    height: 100px;
}
.sp-curved-2{
    background-color: #eff3f9;
    border-radius: 0% 0% 100% 100%;
    height: 100px;
}