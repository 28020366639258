.strategic-partner{
    /* margin-bottom: 128px; */
}

.strategic-partner .strategic-partner-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 8px;
    margin-right: auto;
}