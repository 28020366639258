.modal-delete-admin-content{
    margin-top: 80px;
    padding: 0 18px;
}

.modal-delete-admin-content .modal-delete-admin-title{
    /* display: flex; */
    padding: 0 18px;
    /* justify-content: left; */
    color: #001737;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-align: left;
    margin-right: auto;
    font-size: 20px;
}

.modal-delete-admin-content .modal-delete-admin-form-group{
    /* display: flex; */
    padding: 0 18px;
    /* justify-content: left; */
    color: #001737;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-right: auto;
    margin-bottom: 0;
    font-size: 14px;
}
.modal-delete-admin-content .modal-delete-admin-form-group .form-item-group{
    margin-bottom: 12px;
}
.modal-delete-admin-content .modal-delete-admin-form-group p{
    /* display: flex; */
    margin-bottom: 0;
}

.modal-delete-admin-content .modal-delete-admin-form-group input{
    /* display: flex; */
    width: 100%;
    height: 36px;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.modal-delete-admin-content .modal-delete-admin-form-group .form-submit input{
    width: 12%;
    height: 36px;
    background-color: #0168fa;
    color: #fff;
    margin-top: 24px;
    border: 1px solid #ced4da;
}

.modal-delete-admin-content .button-delete{
    display: flex;
    justify-content: end;
    align-items: end;
}

.modal-delete-admin-content .button-delete .button-confirm-delete{
    background-color: #10b759;
    color: #fff;
    margin: 2px;
}

.modal-delete-admin-content .button-delete .button-cancel-delete{
    background-color: #dc3545;
    color: #fff;
    margin: 2px;
}