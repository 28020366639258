.data-table-container {

}

.data-table-container .button-create{
    display: flex;
    justify-content: right;
    align-items: end;
}

.data-table-container .button-create .MuiButton-root{
    background-color: #10b759;
    color: #fff;
    border-radius: 18px;
    margin: 8px 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 10px;
    width: 80px;
    height: 32px;
}

.data-table-container .fitur-user-data-table{
    background-color: #10b759;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding: 4px;
    border: 2px;
    border-radius: 4px;
}