.sidebar-admin-container{
    /* position: absolute !important; */
    z-index: 10 !important;
    /* width: 100%; */
    border-right: 1px solid #e2e5ed;
    height: 100%;
}
.sidebar-admin-container .left-side-container{
}
.sidebar-admin-container .right-side-container{
    display: none !important;
}

.sidebar-container-admin{
    background-color: #fff;
}

.sidebar-container-admin #sidebar-admin{
    /* background-color: brown; */
    padding: 24px;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar-container-admin .sidebar-admin .sidebar-admin-title{
    color: #031D6B;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 24px;
}

.sidebar-container-admin .sidebar-admin .sidebar-admin-title .sidebar-admin-title-vp{
    color: #0168fb;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 24px;
}

.sidebar-container-admin .sidebar-admin .sidebar-admin-username{
    color: #001737;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-align: left;
    margin: 8px 0 0 0;
    margin-right: auto;
    font-size: 18px;
}

.sidebar-container-admin .sidebar-admin .sidebar-admin-email{
    color: #001737;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    margin: 2px 0 0 0;
    margin-right: auto;
    font-size: 12px;
}

.sidebar-container-admin .sidebar-admin .sidebar-admin-profile{
    margin: 24px 0 18px 0;
}

.sidebar-container-admin .sidebar-admin .sidebar-admin-profile .item-grid-profile{
    /* margin: 24px 0 18px 0; */
}
.sidebar-container-admin .sidebar-admin .sidebar-admin-profile .item-grid-logout{
    /* margin: 24px 0 18px 0; */
    display: flex;
    justify-content: end;
    align-items: center;
}
.sidebar-container-admin .sidebar-admin .sidebar-admin-profile .item-grid-logout button{
    border: 0;
    color: #bdbdbd;
    background-color: rgba(0,0,0,0);
}

.sidebar-admin .sidebar-admin-home{
    margin: 12px 0;
}

.MuiAccordion-admin-sidebar{
    box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.MuiAccordionSummary-admin-sidebar{
    padding: 0 !important;
}

.MuiAccordionTitle-admin-sidebar{
    color: #001737 ;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 700 !important;
    text-align: left;
    margin-bottom: 0 !important;
    margin-right: auto !important;
    font-size: 13px !important;
}

.MuiAccordionSummary-root{
    min-height: 48px !important;
}
.MuiAccordionSummary-content{
    margin: 12px 0 !important;
}

.MuiAccordionContent-admin-sidebar{
    padding: 0 !important;
}

.MuiAccordionContent-admin-sidebar .MuiAccordionContentText-admin-sidebar{
    color: #001737 ;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 400 !important;
    text-align: left;
    margin-bottom: 0 !important;
    margin-right: auto !important;
    font-size: 12px !important;
}

.MuiAccordionContent-admin-sidebar .MuiAccordionContentText-admin-sidebar .a-sidebar{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.MuiAccordionContent-admin-sidebar .MuiAccordionContentText-admin-sidebar .a-sidebar span{
    margin-left: 8px;
}
