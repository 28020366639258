.accordion-header{
    color: #687281;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-align: left;
    margin-bottom: 8px;
    margin-right: auto;
    font-size: 18px;
}

.accordion-item-detail{
    text-align: left;
    color: #687281;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-align: left;
    font-size: 1rem;
}