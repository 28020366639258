.question-section{
    width: 100%;
    /* margin-bottom: 180px; */
}

.question-section TextField{
    margin-bottom: 48px;
}


.question-section .question-section-title{
    text-align: left;
    margin-bottom: 32px;
}
.question-section .question-section-title h3{
    padding: 0;
}

.question-section .question-section-contact{
    text-align: left;
    padding-left: 18px;
}

.question-section .question-section-contact .footer-contact-content {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 8px;
}

.question-section-form{
    padding-left: 18px;
    text-align: left;
}

.question-section-button{
    margin-top: 16px !important;
    color: #fff !important;
    background-color: #b98528 !important;
    border: solid 1px #b98528 !important;
    border-radius: 18px !important;
    padding: 6px 18px !important;
}
.question-section-button:hover{
    color: #fff !important;
    background-color: #c89f56 !important;
    border: solid 1px #fff !important;
    border-radius: 18px !important;
}