
.footer{
    background-color: #ededed !important;
}
.footer .curved-1{
    background-color: #eff3f9;
    border-radius: 0% 0% 100% 100%;
    height: 100px;
    margin-bottom: 64px;
}
.App-footer{
    background-color: #ededed !important;
    margin-bottom: 0;
}

.footer-business-solution .footer-business-solution-title {
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 0 !important;
    margin-right: auto;
}
.footer-business-solution .footer-business-solution-grid-container .footer-business-solution-grid-item a{
    font-weight: 700;
}
.footer-other .footer-other-grid-container .footer-other-grid-item a{
    font-weight: 700;
    color: #000;
}
.footer-other .footer-other-title {
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 0 !important;
    margin-right: auto;
}

.footer-subscribe .footer-subscribe-title{
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 0 !important;
    margin-right: auto;
}
.footer-subscribe .footer-subscribe-text-field{
    margin-bottom: 12px;
}
.footer-subscribe .footer-subscribe-text-field .button-subscribe-footer{
    height: 100%;
    background-color: #000 !important;
}
.footer-subscribe .footer-subscribe-text-field .button{
    height: 100%;
    background-color: #000;
}
.footer-subscribe .footer-subscribe-socmed{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.footer-subscribe .footer-subscribe-socmed a{
    margin-right: 12px;
}
.footer-subscribe .footer-subscribe-socmed img{
    height: 100%;
    width: auto;
    object-fit: contain;
    /* margin-right: 18px; */
}
.footer-subscribe .footer-subscribe-app-download{
    /* height: 100%; */
}