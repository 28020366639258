
.error-page{
padding: 80px;
}
.error-page h1{
    padding-top: 18vw;
    padding-bottom: 18vw;
    display: flex;
    justify-content: center;
    align-items: center;
}