.business-solution{
    /* margin-bottom: 128px; */
}

.business-solution .grid-item-business{
    position: relative;
}
.business-solution .grid-item-business .img-design{
    width: 100%;
    height: 280px;
    /* background-size: cover; */
    position: relative;
    /* padding-left: 12px; */
}
.business-solution .grid-item-business .img-bar{
    height: 20%;
    /* height: 50px; */
    z-index: 2;
    position: absolute;
    top: 8px;
    left: -16px;
}


.business-solution-sidebar{
    text-align: left;
    margin-top: 64px;
}
.business-solution-sidebar .business-solution-sidebar-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 24px;
}
.business-solution-sidebar .business-solution-sidebar-desc{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.business-solution-sidebar .business-solution-sidebar-button{
    font-family: 'Raleway', sans-serif;
    color: #b98528;
    border: solid 1px #b98528;
    border-radius: 8px;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    margin: 4px;
}
.business-solution-sidebar .business-solution-sidebar-button:hover{
    color: #fff;
    background-color: #b98528;
    border: solid 1px #fff;
}


.img-dialog{
    height: 100%;
    width: 80vw;
    object-fit: contain;
}