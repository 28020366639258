.private-admin{
}
.private-admin .private-admin-content{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #031d6b;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 24px;
    margin-top: 20vw;
}